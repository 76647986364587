<template>
  <div class="layout-input-search">
    <esmp-icon
      class="layout-input-search__icon"
      name="search"
    />

    <esmp-input
      v-model="localQuery"
      class="layout-input-search__input"
      label="Начните поиск отсюда"
      @input="debouncedSearch"
      @keyup.native.enter="search"
    />

    <div class="layout-input-search__divider" />

    <esmp-select
      v-model="searchType"
      class="layout-input-search__select"
      @on-change="search"
    >
      <esmp-select-option
        v-for="item in searchTypes"
        :value="item.id"
        :key="item.id"
      >
        {{ item.label }}
      </esmp-select-option>
    </esmp-select>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { MIN_QUERY_LENGTH, SEARCH_DELAY, SEARCH_TYPES } from '@/constants/search';

// TODO: поменять в esmp-input проп label на placeholder

export default {
  name: 'LayoutInputSearch',

  data() {
    return {
      searchTypes: Object.freeze(SEARCH_TYPES),
    };
  },

  computed: {
    ...mapState('search', {
      query: 'query',
      type: 'type',
      services: 'services',
      tickets: 'tickets',
      loading: 'loading',
    }),
    ...mapGetters('user', ['selectedOrLoggedInUser']),

    searchType: {
      get() {
        return this.type;
      },

      set(value) {
        this.setSearchType(value);
      },
    },

    dropDownResults() {
      return this.searchType === 1 ? this.services : this.tickets;
    },

    localQuery: {
      get() {
        return this.query;
      },

      set(value) {
        const trimmedQuery = value.trim();

        if (trimmedQuery !== this.query) {
          this.setQuery(trimmedQuery);
        }
      },
    },
  },

  watch: {
    selectedOrLoggedInUser() {
      this.search();
    },
  },

  created() {
    this.debouncedSearch = debounce(this.search, SEARCH_DELAY);
  },

  methods: {
    ...mapActions('search', {
      sendSearchRequest: 'search',
      setQuery: 'setQuery',
      setSearchType: 'setType',
    }),

    search() {
      if (
        this.localQuery
        && !this.loading
        && this.localQuery.length >= MIN_QUERY_LENGTH
      ) {
        // go to search page
        if (this.$route.name !== 'Search') {
          this.$router.push({ name: 'Search' });
        }

        this.sendSearchRequest(this.localQuery);
      }
    },
  },
};
</script>

<style lang="scss">
.layout-input-search {
  margin-bottom: math.div($gap, 2);
  background: $color-white;
  border-radius: $base-border-radius;
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 24px;
  box-shadow: $base-shadow-xl;

  @include for-size(phone-portrait-down) {
    padding: 5px 6px 5px 16px;
    .esmp-select-content {
      width: 0;
      overflow: hidden;
    }
    .esmp-select-head {
      padding-left: 0;
    }
    .esmp-select-postfix {
      margin-left: 0;
    }
  }

  &__icon {
    color: $color-black-op-50;
  }

  &__input {
    flex-grow: 1;
  }

  &__divider {
    height: $gap;
    width: 1px;
    background: $color-black-op-10;
    margin: 0 8px;
  }

  &__select {
    width: auto;
    max-width: 180px;
    color: $color-grayscale-80;
  }

  .esmp-input-element,
  .esmp-select-head {
    background: none;
  }
  .esmp-input-label {
    color: $color-black-op-75;
    font-weight: 500;
    @include for-size(phone-portrait-down) {
      font-size: 14px;
      padding: 0 0 0 6px;
    }
  }
}
</style>
